<template>
  <div class="bg-main">    
    <b-container class="pt-4 pb-4 my-4">
      <div class="col-md-8 offset-md-2 text-center my-5">
        <h3 class="text-danger">Vérification de l'adresse email</h3>
      </div>
      <div class="col-md-8 offset-md-2">
        <b-card class="border-none px-4">
          <div class="text-center display-  py-4">Veuillez entrer votre adresse courriel pour recevoir un code sécurité</div>
          <div>
            <b-form>
              <b-form-group
                id="input-group-1"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="email"
                  type="email"
                  placeholder="Enter email"
                  required
                ></b-form-input>
              </b-form-group>
            </b-form>
          </div>
        <div class="d-flex justify-content-center pt-2">
          <b-btn @click="submit" variant="danger">Vérifier l'adresse</b-btn>
        </div>
      </b-card>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    ...mapActions({
      verifyEmail:'emailVerifier/verifyEmail'
    }),
    submit () {
      this.verifyEmail(this.email).then(data => {
        console.log(data)
        this.$router.push({ name: 'email.verification' })
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>